import { Reducable } from "@/classes/Reducable";

export class FeeBehaviorRule extends Reducable {
  constructor(feeBehaviorRule = null) {
    feeBehaviorRule = Object.assign(
      {
        effectiveDate: undefined,
        endDate: undefined,
        plateClassID: undefined,
        countyID: undefined,
        cityID: undefined,
        hasFeeID: undefined,
        wheelTaxCode: undefined,
        titleBehaviorMask: undefined,
        registrationBehaviorMask: undefined,
        otherBehaviorMask: undefined,
        hasFeeTypeCode: undefined
      },
      feeBehaviorRule
    );
    super(feeBehaviorRule);
  }

  getReduced() {
    const reduced = super.getReduced();

    return reduced;
  }
}
