<template>
  <div class="county-fee-maintenance panel bootstrap">
    <div class="filters">
      <v-select
        label="Fee Name"
        v-model="feeFilter.feeName"
        @change="applyFilter"
        :items="feeNames"
      />
      <v-select
        label="Plate Class"
        v-model="behaviorFilter.plateClassCode"
        @change="applyFilter"
        :items="plateClassOptions"
      />
      <v-select
        v-if="cities.length > 0"
        label="City"
        v-model="behaviorFilter.city"
        @change="applyFilter"
        :items="cities"
        item-text="cityName"
        item-value="cityID"
      />
    </div>
    <b-table
      class="fee-behaviors"
      sticky-header="100%"
      :items="filteredFeeBehaviors"
      :fields="feeBehaviorFields"
      :no-border-collapse="true"
      small
    >
      <template v-slot:cell(name)="data">
        <v-text-field
          v-model="data.item.fee.feeName"
          @change="data.item.changed = true"
        ></v-text-field>
      </template>
      <template v-slot:cell(amount)="data">
        <v-text-field
          v-model="data.item.fee.feeAmount"
          @change="data.item.changed = true"
        ></v-text-field>
      </template>
      <template v-slot:cell(optional)="data">
        <v-checkbox
          v-model="data.item.optionalFeeInd"
          @change="data.item.changed = true"
        ></v-checkbox>
      </template>
      <template v-slot:cell(city)="data">
        <v-select
          v-model="data.item.cityID"
          :items="cities"
          @change="data.item.changed = true"
        ></v-select>
      </template>
      <template v-slot:cell(alwaysCharge)="data">
        <v-checkbox
          v-model="data.item.alwaysCharge"
          @change="
            modifyDefaultZeroFees(data.item.fee.feeName, data.item.alwaysCharge)
          "
        ></v-checkbox>
      </template>
      <template v-slot:cell(plateClassCode)="data">
        <v-select
          v-model="data.item.plateClassCode"
          :items="plateClassOptions"
          @change="data.item.changed = true"
        />
      </template>
      <template v-slot:cell(effectiveDate)="data">
        <v-menu
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="data.item.effectiveDate"
              prepend-icon="event"
              placeholder="YYYY-MM-DD"
              v-on="on"
              @change="data.item.changed = true"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="data.item.effectiveDate"
            no-title
            scrollable
            :allowed-dates="allowedEffectiveDates"
          ></v-date-picker>
        </v-menu>
      </template>
      <template v-slot:cell(endDate)="data">
        <v-menu
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="data.item.endDate"
              prepend-icon="event"
              placeholder="YYYY-MM-DD"
              v-on="on"
              @change="data.item.changed = true"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="data.item.endDate"
            no-title
            scrollable
            :allowed-dates="allowedEndDates"
          ></v-date-picker>
        </v-menu>
      </template>
    </b-table>
    <v-btn fixed fab bottom right @click="addFeeBehavior">
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <v-btn
      fixed
      fab
      bottom
      right
      class="save"
      :disabled="!changesExist"
      @click="save"
    >
      <v-icon>save</v-icon>
    </v-btn>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { FeeBehaviorRule } from "@/classes/feemaintenance/FeeBehaviorRule";
import { Fee } from "@/classes/feemaintenance/Fee";

export default {
  data() {
    return {
      console: console,
      issuanceFeeIds: [201, 2904],
      feeFilter: {
        feeName: null
      },
      behaviorFilter: {
        plateClassCode: null,
        city: null
      },
      fees: [],
      selectedFee: null,
      feeBehaviors: [],
      filteredFeeBehaviors: [],
      cities: []
    };
  },
  computed: {
    changesExist() {
      return true; // todo> make this reactive
    },
    feeBehaviorFields() {
      return [
        { label: "Name", key: "name" },
        { label: "Amount", key: "amount" },
        { label: "PlateClass", key: "plateClassCode" },
        { label: "Effective Date", key: "effectiveDate" },
        { label: "End Date", key: "endDate" },
        { label: "City", key: "city" },
        { label: "Always Charge", key: "alwaysCharge" },
        { label: "Optional", key: "optional" }
      ];
    },
    feeNames() {
      const feeNames = [{ text: "All", value: null }];

      for (let i = 0; i < this.fees.length; i++) {
        const feeName = this.fees[i].feeName;
        if (!feeNames.includes(feeName)) feeNames.push(feeName);
      }

      return feeNames;
    },
    plateClassOptions() {
      const plateClassOptions = [
        {
          text: "Default",
          value: null
        }
      ];
      this.feeBehaviors.forEach(behavior => {
        if (
          behavior.plateClassCode !== undefined &&
          !plateClassOptions.includes(behavior.plateClassCode)
        ) {
          plateClassOptions.push(behavior.plateClassCode);
        }
      });
      return plateClassOptions;
    }
  },
  methods: {
    allowedEffectiveDates(date) {
      return date > dayjs().format("YYYY-MM-DD");
    },
    allowedEndDates(date) {
      return this.allowedEffectiveDates(date);
    },
    async refreshFeeBehaviors() {
      this.fees =
        (await this.$api.getFeeMaintenanceOptions(
          this.$store.getters.countyId
        )) || [];
      this.feeBehaviors = [];

      for (let feeIndex = 0; feeIndex < this.fees.length; feeIndex++) {
        if (this.fees[feeIndex].feeBehaviors === null) {
          // todo> this condition fills in blank fee behaviors and may not be necessary in the future
          let feeBehavior = {
            fee: this.fees[feeIndex],
            effectiveDate: "0001-01-01",
            endDate: "9999-12-31",
            plateClassCode: null
          };
          feeBehavior = new FeeBehaviorRule(feeBehavior);
          feeBehavior.feeID = this.fees[feeIndex].feeID;
          feeBehavior.alwaysCharge =
            this.defaultZeroFees[feeBehavior.fee.feeName] !== undefined;
          feeBehavior.changed = false;
          this.feeBehaviors.push(feeBehavior);
        } else {
          for (
            let behaviorIndex = 0;
            behaviorIndex < this.fees[feeIndex].feeBehaviors.length;
            behaviorIndex++
          ) {
            let feeBehavior = this.fees[feeIndex].feeBehaviors[behaviorIndex];
            feeBehavior.fee = this.fees[feeIndex];
            feeBehavior.effectiveDate = feeBehavior.effectiveDate.substr(0, 10);
            feeBehavior.endDate = feeBehavior.endDate.substr(0, 10);
            feeBehavior = new FeeBehaviorRule(feeBehavior);
            feeBehavior.alwaysCharge =
              this.defaultZeroFees[feeBehavior.fee.feeName] !== undefined;
            feeBehavior.changed = false;
            this.feeBehaviors.push(feeBehavior);
          }
        }
        delete this.fees[feeIndex].feeBehaviors;
      }
    },
    modifyDefaultZeroFees(feeName, actionIsAdd) {
      if (actionIsAdd) this.defaultZeroFees[feeName] = {};
      else delete this.defaultZeroFees[feeName];
    },
    applyFilter() {
      const feeIndex = {}; // a set of feeName-indexed behavior representations used to ensure each fee is represented by the most specific behavior
      this.filteredFeeBehaviors = [];
      this.feeBehaviors.forEach(behavior => {
        // apply fee filter
        if (
          !Object.keys(this.feeFilter).every(key => {
            return (
              this.feeFilter[key] === null ||
              this.feeFilter[key] === behavior.fee[key]
            );
          })
        )
          return;

        // create a behavior representation for metadata about the behavior needed for filtering
        const behaviorRep = {
          // fee representation that gets added to feeIndex if the behavior passes the filter
          index: null, // index the behavior will occupy in filteredFeeBehaviors array if the behavior passes the filter. this is used to replace the feebehavior if a fee with higher specificity is found.
          specificity: 0 // number of matching, non-default filter properties
        };

        // apply behavior filter
        if (
          Object.keys(this.behaviorFilter).every(key => {
            if (this.behaviorFilter[key] === behavior[key])
              behaviorRep.specificity++;
            return (
              this.behaviorFilter[key] === behavior[key] ||
              [null, undefined].includes(behavior[key])
            );
          })
        ) {
          // check to see if a behavior for the same fee exists in the filtered set
          if (feeIndex[behavior.fee.feeName] !== undefined) {
            if (
              feeIndex[behavior.fee.feeName].specificity >
              behaviorRep.specificity
            )
              return;
            else {
              this.filteredFeeBehaviors.splice(
                feeIndex[behavior.fee.feeName].index,
                1
              );
            }
          }
          behaviorRep.index = this.filteredFeeBehaviors.length;
          this.filteredFeeBehaviors.push(behavior);
          feeIndex[behavior.fee.feeName] = behaviorRep;
        }
      });
    },
    filterFeeBehaviors(filter) {
      const keys = Object.keys(filter);
      this.filteredFeeBehaviors = this.feeBehaviors.filter(behavior => {
        return keys.every(key => {
          return filter[key] == behavior[key];
        });
      });
    },
    async save() {
      const promises = [this.$api.updateDefaultZeroFees(this.defaultZeroFees)];
      for (let i = 0; i < this.feeBehaviors.length; i++) {
        const feeBehavior = this.feeBehaviors[i];

        if (feeBehavior.changed) {
          promises.push(
            this.$api.updateFeeMaintenanceBehaviorRule(feeBehavior.getReduced())
          );
        }
      }

      await Promise.all(promises);
      this.$root.$emit("push-alert", "Fees updated successfully", {
        color: "success"
      });
    },
    focusLastRow() {
      this.$nextTick(() => {
        document.querySelector(".b-table tbody tr:last-child input").focus();
      });
    },
    addFeeBehavior() {
      const feeBehavior = new FeeBehaviorRule({ fee: new Fee() });
      feeBehavior.changed = false;
      this.feeBehaviors.push(feeBehavior);
      this.applyFilter();
      this.focusLastRow();
    },
    displayDefaults() {
      this.filterFeeBehaviors({ plateClassCode: null });
    }
  },
  async created() {
    [this.cities, this.defaultZeroFees] = await Promise.all([
      this.$api.getCities() || [],
      (await this.$api.getDefaultZeroFees()).defaultzerofees,
      this.refreshFeeBehaviors()
    ]);
    this.displayDefaults();
  }
};
</script>

<style lang="scss">
@import "@/assets/css/global-variables.scss";

.county-fee-maintenance {
  display: flex;
  flex-direction: column;
  height: calc(100vh - #{$nav-height} - #{$panel-margin * 2});
}

.v-btn--absolute.v-btn--right.save,
.v-btn--fixed.v-btn--right.save {
  right: 100px;
}

.panel {
  margin-right: 0;
  margin-left: 0;
  margin-bottom: 0;
}

.filters {
  display: flex;
}
</style>
