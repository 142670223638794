import { Reducable } from "@/classes/Reducable.js";

export class Fee extends Reducable {
  constructor(fee = null) {
    fee = Object.assign(
      {
        feeID: undefined,
        feeName: undefined,
        feeAmount: undefined,
        feeTypeCode: undefined,
        feeChargeIncrement: undefined,
        initialRenewalCode: undefined,
        stateCountyLocalCode: undefined,
        baseSummaryInd: undefined,
        overrideFeeInd: undefined,
        optionalFeeInd: undefined,
        refundableFeeInd: undefined,
        feeDescription: undefined,
        feeBehaviors: undefined
      },
      fee
    );
    super(fee);

    // client-only properties (these get stripped when calling getReduced)
    this.originalFeeAmount = this.feeAmount;
  }
}
